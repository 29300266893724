<template>

  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <router-link :to="{ name: BookPath.name }" class="text-capitalize  text-gray-900 font-semibold">
          Books</router-link> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Add New</span>
      </label>
      <h4 class="text-xl capitalize">New Book</h4>
    </div>
    <form class="bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4"  @submit="onSubmit"  v-if="show" id="form-new">
      <div class="flex justify-between items-end">
        <div>
          <h4 class="text-xl font-bold capitalize">Create New Book</h4>
        </div>
      </div>
      <div class="mt-3 flex justify-between">
        <div class="w-full flex justify-between">
          <div class="w-1/2 pr-10">
            <div class="block"  v-if="!isProfileVendor">
              <label class="font-semibold text-gray-600">Brand</label>
              <multiselect v-model="form.brand" label="name" track-by="name" placeholder="Enter Brand" class="w-full"
                open-direction="bottom" required="required" :options="brandSearchOptions" :searchable="true"
                :loading="isBrandSearch" :close-on-select="true" :options-limit="10" :multiple="false"
                :allow-empty="false" @search-change="brandFind" @select="brandSelected">
              </multiselect>
            </div>
            <div class="block " :class="!isProfileVendor ?'mt-3' : 'mt-0'">
              <label class="font-semibold text-gray-600">Author</label>
              <multiselect v-model="form.author" label="name" track-by="name" placeholder="Enter Author"
                open-direction="bottom" :options="authorSearchOptions" :searchable="true" :loading="isAuthorSearch"
                :close-on-select="true" :options-limit="10" :multiple="true" @search-change="authorFind"
                @input="authorSelected">
              </multiselect>
            </div>
            <div class="block mt-3">
              <label class="font-semibold text-gray-600">Display Name (Item’s Title)</label>
              <input id="display" v-model="form.name" class="w-full bg-gray-50 rounded-lg border " type="text"
                placeholder="Enter Display Name" required />
            </div>
            <div class="grid gap-4 mt-3 grid-cols-2">
              <div>
                <label class="font-semibold text-gray-600">Issue Number</label>
                <input class="w-full bg-gray-50 rounded-lg border " id="issue" :disabled="userData.role_id === 5"
                  v-model="form.issue_number" type="text" placeholder="Enter Issue Number" required />
              </div>
              <div>
                <label class="font-semibold text-gray-600">Edition Code</label>
                <input id="code" v-model="form.edition_code" class="w-full bg-gray-50 rounded-lg border " type="text"
                  placeholder="Enter Edition Code" required />
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="w-1/2 pl-10">
            <div class="block">
              <label class="font-semibold text-gray-600">Languages</label>
              <multiselect placeholder="Enter languages" open-direction="bottom" v-model="form.languages" label="name"
                track-by="name" :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
                :close-on-select="true" :options-limit="10" :multiple="true" required>
              </multiselect>
            </div>
            <div class="block mt-3">
              <label class="font-semibold text-gray-600">Countries</label>
              <multiselect placeholder="Enter Countries" open-direction="bottom" v-model="form.countries" label="name"
                track-by="name" :custom-label="nameWithISO" :options="countriesSearchOptions"
                :loading="isCountriesSearch" :searchable="true" :close-on-select="true" :options-limit="10"
                :multiple="true">
              </multiselect>
            </div>
            <div class="block mt-3">
              <label class="font-semibold text-gray-600">Categories</label>
              <multiselect v-model="form.categories" label="name" track-by="name" placeholder="Enter Categories"
                open-direction="bottom" :options="categoriesSearchOptions" :loading="isCategoriesSearch"
                :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true"
                @search-change="categoriesFind">
              </multiselect>
            </div>
          </div>
        </div>

      </div>
      <h4 class="text-xl font-bold capitalize block border-t w-full mt-4 pt-3">Detail</h4>
      <div class="justify-start flex">
        <div class="w-1/2 pr-10">
          <DatetimeCustom :label="'Release Date (GMT ' + timezoneString() + ')'" v-model="form.release_date">
          </DatetimeCustom>
          <DatetimeCustom class="mt-3" :label="'Release Schedule (GMT ' + timezoneString() + ')'"
            v-model="form.schedule_date"></DatetimeCustom>
          <div class="grid gap-4 grid-cols-2 mt-3">
            <div>
              <div class="mt-0">
                <label class="font-semibold text-gray-600 mb-3">Printed Price Currency</label>
                <t-select id="currency" v-model="form.currency" :options="currencys" required></t-select>
              </div>
              <div class="mt-3">
                <label class="font-semibold text-gray-600 mb-3">Content Type</label>
                <t-select class="bg-gray-50" id="content_type" v-model="form.content_type" :options="contents"
                  required></t-select>
              </div>
              <div class="mt-3">
                <label class="font-semibold mb-3 text-gray-600">Distribution Countries</label>
                <t-select id="distribution" v-model="form.distribution" :options="distributionSearchOptions" disabled
                  required></t-select>
              </div>
            </div>
            <div>
              <div class="mt-0">
                <label class="font-semibold mb-3 text-gray-600">Printed Price</label>
                <input id="price" v-model="form.price" type="number" class="w-full bg-gray-50 rounded-lg border"
                  step="any" placeholder="99000 (no sparator)" required />
              </div>
              <div class="mt-3">

                <label class="font-semibold  mb-3 text-gray-600">Parental Control</label>
                <t-select class="bg-gray-50" id="parentals" v-model="form.parental" :options="parentals"
                  required></t-select>
              </div>
              <div class="mt-3">
                <label class="font-semibold mb-3 text-gray-600">Reading Direction</label>
                <t-select id="readings" v-model="form.reading" :options="readings" required></t-select>
              </div>
            </div>

          </div>
          <div class="flex justify-start">
            <div class="mr-3">
              <t-checkbox v-model="form.is_active" :value="true" :unchecked-value="false" />
              <label class="text-gray-600 font-semibold mt-3 ml-2">Active</label>
            </div>
            <div v-if="!isProfileVendor">
              <t-checkbox id="content" class="ml-2"
                :options="contents"
                v-model="form.is_internal_content"
                :value="true"
                :unchecked-value="false"
                v-if="!isProfileVendor" />
              <label class="text-gray-600 font-semibold mt-3 ml-2">Internal Content</label>
            </div>

          </div>
          <div class="flex justify-start mt-2">
            <button class="bg-blue-700 p-2 px-2 text-white rounded-lg " type="submit" form="form-new"
              :hidden="isLoading">
              Save
            </button>
            <button
              class="bg-white border-blue-700  text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3"
              type="reset"
              >Reset Form</button>
            <button class="bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3 ">

              <router-link :to="{ name: BookPath.name }" class="text-capitalize  text-gray-800 font-normal">
                Cancel</router-link>
            </button>
          </div>
        </div>
        <div class="w-1/2 pl-10">
          <div class="block">
            <label class="font-semibold mb-3 text-gray-600">International Standard Book Number (ISBN)</label>
            <input id="price" v-model="form.isbn" type="number" class="w-full bg-gray-50 rounded-lg border" step="any"
              placeholder="Enter ISBN" required />
          </div>

          <div class="block mt-3">
            <label class="font-semibold mb-3 text-gray-600">Description</label>
            <t-textarea id="description" v-model="form.description" placeholder="Enter something..." rows="5">
            </t-textarea>
          </div>
        </div>
      </div>


    </form>

  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { BookPath } from "../../router/content";
import constant from "../../store/constant";
import EventBus from '../../event-bus'
import DatetimeCustom from "../../components/form/Datetime.vue"

const { ITEM_TYPES } = constant;
delete constant.CONTENT_TYPE.AUDIO;
const {
  PARENTAL_LEVEL_TYPE,
  PRINTED_PRICE_ITEM,
} = constant;

const CONTENT_TYPE = Object.values(constant.CONTENT_TYPE);
const READING_DIRECTION = Object.entries(constant.READING_DIRECTION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PARENTAL_CONTROL = Object.entries(PARENTAL_LEVEL_TYPE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PRINTED_PRICE = Object.values(PRINTED_PRICE_ITEM);

export default {
  name: "newebooks",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      BookPath,
      userData: JSON.parse(localStorage.getItem('session_auth')),
      form: {
        brand: [],
        author: [],
        name: "",
        issue_number: "",
        edition_code: "",
        languages: [{ iso: 'IND', name: 'Indonesia' }],
        countries: [{ iso: 'ID', name: 'Indonesia' }],
        categories: [],
        release_date: moment().toString(),
        schedule_date: moment().toString(),
        price: "",
        currency: 'IDR',
        content: 'PDF',
        parental: 1,
        distribution: null,
        reading: 'left-to-right',
        checked: [],
        is_active: true,
        is_internal_content: false,
        isbn: "",
        description: ""
      },
      currencys: [
        { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      contents: [{ text: "Select Content", value: null }, ...CONTENT_TYPE],
      parentals: [{ text: "Select Parental Type", value: null }, ...PARENTAL_CONTROL],
      readings: [
        { text: "Select Direction", value: null }, ...READING_DIRECTION
      ],
      distributionSearchOptions: [
        { text: "Select Distributin Countries", value: null }
      ],
      show: true,
      brandSearchOptions: [],
      authorSearchOptions: [],
      categoriesSearchOptions: [],
      countriesSearchOptions: [],
      languagesSearchOptions: Object.entries(constant.LANGUAGE_CHOICES).map((item) => ({
        iso: item[0].toUpperCase(),
        name: item[1]
      })),
      isBrandSearch: false,
      isAuthorSearch: false,
      isCategoriesSearch: false,
      isCountriesSearch: false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.ebooks.isError,
      isLoading: (state) => state.ebooks.isLoading,
      errorMessage: (state) => state.ebooks.errorMessage,
      successMessage: (state) => state.ebooks.successMessage,
      item: (state) => state.ebooks.item,
      auth: (state) => state.auth.auth,
      isProfileVendor: (state) => state.profile?.vendor_id,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
          EventBus.$emit('show-toast', {
            message:this.errorMessage,
            type: 'success',
          });
    //  this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;  
          EventBus.$emit('show-toast', {
            message:this.successMessage,
            type: 'success',
          });
      this.$router.push(BookPath);
    },
  },
  created() {
    this.setCountriesOptions();
    this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('brands', ['searchBrands', 'getCategoriesByBrandIdAdapter']),
    ...mapActions('author', ['searchAuthor']),
    ...mapActions('ebooks', ['postNewBooks']),
    ...mapActions('items', ['searchCategoriesV2', 'getCountries', 'generateEditionCodeBookMagazine']),

    onSubmit(event) {
      event.preventDefault();
      if (!this.validateForm()) return;

      const data = this.form;
      const payload = {
        name: data.name,
        edition_code: data.edition_code,
        content_type: data.content.toLowerCase(),
        printed_price: data.price,
        brand_id: data.brand.id,
        author_id: data.author.map(item => item.id),
        categories_id: data.categories.map(item => item.id),
        release_date: data.release_date,
        release_schedule: data.schedule_date,
        gtin13: data.isbn?.length == 13 ? data.isbn : '',
        gtin14: data.isbn?.length == 14 ? data.isbn : '',
        description: data.description,
        printed_currency_code: data.currency,
        issue_number: data.issue_number,
        is_active: data.is_active,
        is_internal_content: data.is_internal_content,
        parentalcontrol_id: data.parental,
        reading_direction: data.reading,
        item_distribution_country_group_id: data.distribution,
        countries: data.countries.map(item => item.iso.toLowerCase()),
        languages: data.languages.map(item => item.iso.toLowerCase()),
        vendor_id: this.isProfileVendor,
      }
      this.postNewBooks(payload);
    },

    validateForm() {
      this.form.edition_code = this.form?.edition_code.trim();
      this.form.isbn = this.form?.isbn.trim();

      if (this.form?.edition_code?.includes(' ')) {
            EventBus.$emit('show-toast', {
          message:'Edition Code!! cannot contain spaces',
          type: 'success',
          });
        return false;
      }

      if (
        !this.form?.isbn?.length ||
        this.form?.isbn?.length < 13 ||
        this.form?.isbn?.length > 14
      ) {
     //   this.$swal('ISBN!!', 'ISBN must be have length 13/14', 'warning');
        EventBus.$emit('show-toast', {
          message:'ISBN must be have length 13/14'
          });
        return false;
      }
      if (this.form?.isbn?.includes(' ')) {
       // this.$swal('ISBN!!', 'cannot contain spaces', 'warning');
        EventBus.$emit('show-toast', {
          message:'cannot contain spaces'
          });
        return false;
      }

      return true;
    },

    brandFind(query) {
      if (!query) return;

      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          type: constant.ITEM_TYPES.BOOK.toLowerCase(),
          limit: 10,
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },

    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload).then((response) => {
          this.authorSearchOptions = response.data.data.rows;
          this.isAuthorSearch = false;
        }).catch(() => {
          this.isAuthorSearch = false;
        });
      }, 600);
    },

    categoriesFind(query) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          name: query,
          item_type: ITEM_TYPES.BOOK.toLowerCase(),
          is_active: 1,
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          }).catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    async brandSelected(data) {
      this.form.languages = this.languagesSearchOptions
        .filter(
          item => data.default_languages.includes(item.iso.toLowerCase())
        );
      this.form.countries = this.countriesSearchOptions
        .filter(
          item => data.default_countries.includes(item.iso.toLowerCase())
        );
      this.form.categories = data.default_categories;
      this.form.name = data.name;
      this.form.edition_code = await this.generateEditionCodeBookMagazine(data);
      const defaultCategories = await this.getCategoriesByBrandIdAdapter({ id: data.id });
      this.form.categories = defaultCategories?.map(val => ({
        name: val.categoriesName,
        id: val.categoryId,
      }));
    },

    authorSelected(data) {
      const issue_number = data.map(item => item.name).join(', ');
      this.form.issue_number = issue_number;
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: true,
        timer,
        timerProgressBar: true,
      });
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },

    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  }
};
</script>

<style lang="css">
.fix-bot-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}
</style>
